<template>
    <div
        v-if="!loading"
        class="d-flex align-items-center justify-content-center vh-100"
    >
        <div class="card p-4" style="width: 100%; max-width: 450px">
            <form class="form-horizonal" @submit.prevent="handleSignIn">
                <div class="card-header text-center mb-4">
                    <img
                        :src="systemLogo"
                        alt="Logo"
                        class="img-fluid"
                        style="max-width: 150px"
                    />
                    <h4 class="mt-3">{{ systemTitle }}</h4>
                </div>
                <div class="card-body">
                    <div class="form-group mb-3">
                        <label for="username" class="form-label">{{
                            $t('login.login_msg__email_address')
                        }}</label>
                        <input
                            type="text"
                            v-model="username"
                            class="form-control"
                            id="username"
                            placeholder="Enter your username"
                            autocomplete="off"
                            required
                        />
                    </div>
                    <div class="form-group mb-3">
                        <label for="password" class="form-label">{{
                            $t('login.login_msg__password')
                        }}</label>
                        <div class="input-group">
                            <input
                                :type="passwordVisible ? 'text' : 'password'"
                                v-model="password"
                                class="form-control"
                                id="password"
                                placeholder="Enter your password"
                                autocomplete="off"
                                required
                            />
                            <div class="input-group-append">
                                <button
                                    type="button"
                                    class="btn btn-outline-primary"
                                    @click="togglePasswordVisibility"
                                >
                                    <i
                                        :class="
                                            passwordVisible
                                                ? 'fa fa-eye-slash'
                                                : 'fa fa-eye'
                                        "
                                        style="color: #427c89"
                                    ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary w-100">
                        {{ $t('login.login_msg__sign_in') }}
                    </button>
                </div>
            </form>
            <div class="d-flex justify-content-between mt-3">
                <button class="btn btn-link p-0" @click="forgotPassword">
                    <i class="fa fa-question-circle"></i>
                    {{ $t('login.login_msg__forgot_password') }}
                </button>
                <button class="btn btn-link p-0" @click="privacyPolicy">
                    <i class="fa fa-shield"></i>
                    {{ $t('login.login_msg__privacy_policy') }}
                </button>
            </div>
            <LanguageSelector
                class="mt-3"
                :languages="languages"
                :currentLanguage="currentLanguage"
                @language-changed="changeLanguage"
            />
            <div
                v-if="errorMessage"
                class="alert alert-danger mt-3"
                role="alert"
            >
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import i18n from '@/api/i18n';
import { signin, userSelf, resendVerificationEmail } from '@/api/users';
import apiClient from '@/api/index';
import { setLoading } from '@/plugins/loading';
import LanguageSelector from '@/components/Navbar/LanguageSelector.vue';
import { useLocale } from '@/composables/useLocale';

export default {
    name: 'SigninView',
    components: {
        LanguageSelector,
    },
    setup() {
        const { t } = useI18n();
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const Swal = inject('$swal');
        const { currentLanguage, languages, changeLanguage, fetchLanguages } =
            useLocale();

        const username = ref('');
        const password = ref('');
        const passwordVisible = ref(false);
        const errorMessage = ref('');
        const loading = ref(true);
        setLoading(true);

        const handleSignIn = async () => {
            try {
                setLoading(true);
                const response = await signin({
                    username: username.value,
                    password: password.value,
                });
                if (response.error) {
                    if (response.require_verification) {
                        await Swal({
                            title: t(`login.${response.error}`),
                            icon: 'question',
                            width: '60%',
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: t(
                                'login.login_button__resend_verification_email'
                            ),
                        }).then(async result => {
                            if (result.isConfirmed) {
                                const response = await resendVerificationEmail(
                                    username.value
                                );
                                if (response.status === 200) {
                                    await Swal.fire({
                                        title: t(
                                            'login.login_msg__account_verification_email_resent_successful',
                                            {
                                                email: username.value,
                                            }
                                        ),
                                        icon: 'success',
                                        showConfirmButton: true,
                                    });
                                } else {
                                    Swal.fire({
                                        title: t(`login.${response.error}`),
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                }
                            }
                        });
                        return;
                    }
                    // errorMessage.value = t(`login.${response.error}`, {
                    //     lockout_seconds: response.lockout_seconds,
                    //     lockout_minutes: response.lockout_minutes,
                    //     lockout_limit: response.lockout_limit,
                    // });
                    await Swal.fire({
                        position: 'center',
                        title: t(`login.${response.error}`, {
                            lockout_seconds: response.lockout_seconds,
                            lockout_minutes: response.lockout_minutes,
                            lockout_limit: response.lockout_limit,
                        }),
                        icon: 'error',
                        showConfirmButton: false,
                        showCloseButton: true,
                        timer: 3000,
                    });
                    return;
                }
                const token = response.access;
                store.dispatch('auth/signin', token);

                const { menu_items } = await userSelf();

                if (response.require_change_password) {
                    router.push('/sign-in/change-password');
                    return;
                }

                const redirect = route.query.redirect || menu_items[0].path;
                router.push(redirect);
            } catch (error) {
                console.error('handle signin: ', error);
                errorMessage.value = t(`login.${error.error}`);
            } finally {
                setLoading(false);
            }
        };

        const forgotPassword = () => {
            router.push('/forgot-password');
        };

        const privacyPolicy = () => {
            router.push('/privacy-policy');
        };

        const togglePasswordVisibility = () => {
            passwordVisible.value = !passwordVisible.value;
        };

        onMounted(async () => {
            // Fetch locale
            const locale =
                localStorage.getItem('locale') ||
                store.getters['system/defaultLocale'];

            i18n.global.locale.value = locale;

            await store.dispatch('i18n/loadLocale', locale);

            const responseLang = await apiClient.get(
                `/languages/lang/${locale}?disable_cache=false`,
                { skipAuthorization: true }
            );
            // Set locale messages using current locale
            i18n.global.setLocaleMessage(locale, responseLang.data);

            setLoading(false);
            loading.value = false;

            // Fetch languages
            await fetchLanguages();
        });

        const systemLogo = computed(() => {
            const logoBase64 = store.getters['system/logo'];
            if (logoBase64) {
                return `data:image/png;base64,${logoBase64}`;
            }
            return `${process.env.BASE_URL}assets/img/dhc-logo.png`;
        });

        const systemTitle = computed(() => {
            return store.getters['system/title'] || 'Club Assist SaaS';
        });

        return {
            username,
            password,
            handleSignIn,
            forgotPassword,
            privacyPolicy,
            errorMessage,
            systemTitle,
            systemLogo,
            loading,
            currentLanguage,
            languages,
            changeLanguage,
            passwordVisible,
            togglePasswordVisibility,
        };
    },
};
</script>
