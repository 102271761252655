import apiClient from '.';
import { setLoading } from '@/plugins/loading';

export const signin = async data => {
    try {
        setLoading(true);
        const response = await apiClient.post('/users/account/sign-in', data, {
            skipAuthHandling: true,
            skipAuthorization: true,
        });
        return response.data;
    } catch (error) {
        if (
            error.response &&
            (error.response.status === 401 || error.response.status === 403)
        ) {
            return error.response.data;
        }
    } finally {
        setLoading(false);
    }
};

export const userSelf = async () => {
    try {
        setLoading(true);
        const response = await apiClient.get('/users/self');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

export const fetchUsersFromAPI = async params => {
    try {
        const response = await apiClient.get('/users', {
            params,
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const exportUsersFromAPI = async (params, type) => {
    try {
        if (localStorage.getItem('locale') !== undefined) {
            params.output_language = localStorage.getItem('locale');
        }
        const response = await apiClient.get(`/users/export/${type}`, {
            params,
            responseType: type === 'excel' ? 'blob' : 'text',
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchConfig = async () => {
    try {
        setLoading(true);
        const response = await apiClient.get('/users/configs');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

export const fetchCreateUserConfig = async () => {
    try {
        setLoading(true);
        const response = await apiClient.get('/users/create-account/configs');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

export const fetchEditUserConfig = async () => {
    try {
        setLoading(true);
        const response = await apiClient.get('/users/edit-account/configs');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

export const checkInput = async (url, value) => {
    try {
        const encodedValue = encodeURIComponent(value);
        const response = await apiClient.get(url + encodedValue);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const checkClubExist = async clubId => {
    const response = await apiClient.get(`users/clubs?search=${clubId}`);
    return response.data.length > 0;
};

export const createClub = async clubId => {
    setLoading(true);
    try {
        const response = await apiClient.post('users/clubs', { name: clubId });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

export const createUser = async data => {
    setLoading(true);
    try {
        const response = await apiClient.post('users/create-account', data);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

export const editUser = async (id, data) => {
    setLoading(true);
    try {
        const response = await apiClient.patch(`users/${id}`, data);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

export const getUser = async id => {
    try {
        const response = await apiClient.get(`users/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const sendResetPasswordLink = async email => {
    try {
        const response = await apiClient.post(
            'users/verification/request-password-reset',
            { email }
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const verifyUserVerificationToken = async token => {
    try {
        const response = await apiClient.get(
            `users/verification/validate-user-token/${token}`
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const verifyResetPasswordToken = async token => {
    try {
        const response = await apiClient.get(
            `users/verification/validate-reset-password-token/${token}`
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const verifyUser = async (token, newPassword) => {
    try {
        const response = await apiClient.post('users/verify-user', {
            token: token,
            new_password: newPassword,
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const resetPassword = async (token, newPassword) => {
    try {
        const response = await apiClient.post('users/reset-password', {
            token: token,
            new_password: newPassword,
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const changePassword = async (oldPassword, newPassword) => {
    try {
        const response = await apiClient.post('users/change-password', {
            old_password: oldPassword,
            new_password: newPassword,
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const checkPasswordRule = async password => {
    setLoading(true);
    try {
        const response = await apiClient.post(
            'users/account/check-password-rule',
            { password }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

export const resendVerificationEmail = async email => {
    try {
        const response = await apiClient.post(
            'users/verification/request-user-verification-resend',
            {
                email: email,
                output_language: localStorage.getItem('locale') || 'en',
            }
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const deleteUser = async id => {
    try {
        const response = await apiClient.delete(`users/${id}`);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const resetUserPassword = async username => {
    try {
        const response = await apiClient.post('users/reset-user-password', {
            username: username,
            output_language: localStorage.getItem('locale') || 'en',
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const batchCreateUsers = async formData => {
    setLoading(true);
    try {
        if (localStorage.getItem('locale') !== undefined) {
            formData.append('output_language', localStorage.getItem('locale'));
        }
        const response = await apiClient.post('users/batch-create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        if (
            error.response &&
            (error.response.status === 400 || error.response.status === 500)
        ) {
            return error.response;
        } else {
            throw error;
        }
    } finally {
        setLoading(false);
    }
};