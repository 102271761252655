<template>
    <div class="card p-4" style="width: 100%; max-width: 900px">
        <div v-if="formTitle !== ''" class="card-header">
            <h3>{{ formTitle }}</h3>
        </div>
        <div class="card-body">
            <form @submit.prevent="handleSubmit">
                <div v-if="showOldPassword" class="form-group">
                    <label for="oldPassword" class="control-label">{{
                        $t('users.users_label__password_form_old_password')
                    }}</label>
                    <div class="input-group">
                        <input
                            :type="oldPasswordVisible ? 'text' : 'password'"
                            id="oldPassword"
                            class="form-control"
                            v-model="formData.oldPassword"
                            :disabled="isSubmitting"
                            required
                        />
                        <div class="input-group-append">
                            <button
                                type="button"
                                class="btn btn-outline-primary"
                                @click="toggleOldPasswordVisibility"
                            >
                                <i
                                    :class="
                                        oldPasswordVisible
                                            ? 'fa fa-eye-slash'
                                            : 'fa fa-eye'
                                    "
                                ></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="newPassword" class="control-label">{{
                        $t('users.users_label__password_form_new_password')
                    }}</label>
                    <div class="input-group">
                        <input
                            :type="newPasswordVisible ? 'text' : 'password'"
                            id="newPassword"
                            class="form-control"
                            v-model="formData.newPassword"
                            :disabled="isSubmitting"
                            required
                            @blur="handleNewPasswordBlur"
                        />
                        <div class="input-group-append">
                            <button
                                type="button"
                                class="btn btn-outline-primary"
                                @click="toggleNewPasswordVisibility"
                            >
                                <i
                                    :class="
                                        newPasswordVisible
                                            ? 'fa fa-eye-slash'
                                            : 'fa fa-eye'
                                    "
                                ></i>
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="passwordRuleErrorMessages.length > 0"
                        class="alert alert-danger mt-3"
                        role="alert"
                    >
                        <ul>
                            <li
                                v-for="errorMessage in passwordRuleErrorMessages"
                                :key="errorMessage"
                            >
                                {{ errorMessage }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="form-group">
                    <label for="confirmPassword" class="control-label">{{
                        $t('users.users_label__password_form_confirm_password')
                    }}</label>
                    <div class="input-group">
                        <input
                            :type="confirmPasswordVisible ? 'text' : 'password'"
                            id="confirmPassword"
                            class="form-control"
                            v-model="formData.confirmPassword"
                            :disabled="isSubmitting"
                            autocomplete="off"
                            required
                        />
                        <div class="input-group-append">
                            <button
                                type="button"
                                class="btn btn-outline-primary"
                                @click="toggleConfirmPasswordVisibility"
                            >
                                <i
                                    :class="
                                        confirmPasswordVisible
                                            ? 'fa fa-eye-slash'
                                            : 'fa fa-eye'
                                    "
                                    style="color: #427c89"
                                ></i>
                            </button>
                        </div>
                    </div>
                </div>
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="isSubmitting"
                >
                    {{ buttonText }}
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { checkPasswordRule } from '@/api/users';
import { useI18n } from 'vue-i18n';
import { setLoading } from '@/plugins/loading';

export default {
    name: 'PasswordForm',
    props: {
        showOldPassword: {
            type: Boolean,
            default: false,
        },
        formTitle: {
            type: String,
            default: '',
        },
        buttonText: {
            type: String,
            default: 'Submit',
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const formData = ref({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        });
        const isSubmitting = ref(false);
        const passwordRuleErrorMessages = ref([]);
        const oldPasswordVisible = ref(false);
        const newPasswordVisible = ref(false);
        const confirmPasswordVisible = ref(false);

        const validatePasswordRule = async () => {
            setLoading(true);
            try {
                const response = await checkPasswordRule(
                    formData.value.newPassword
                );
                if (response && response.errors) {
                    passwordRuleErrorMessages.value = response.errors;
                } else {
                    passwordRuleErrorMessages.value = [];
                }
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                ) {
                    passwordRuleErrorMessages.value = error.response.data.error;
                } else {
                    passwordRuleErrorMessages.value = [
                        t('django.system_msg__unexpected_error_occurred'),
                    ];
                }
            } finally {
                setLoading(false);
            }
        };

        const handleSubmit = async () => {
            isSubmitting.value = true; // prevent multiple submissions
            passwordRuleErrorMessages.value = []; // clear previous error messages

            await validatePasswordRule();

            if (props.showOldPassword && !formData.value.oldPassword) {
                passwordRuleErrorMessages.value = [
                    t('users.users_msg__password_form_old_password_required'),
                ];
                isSubmitting.value = false; // allow resubmission
                return;
            }

            if (formData.value.newPassword !== formData.value.confirmPassword) {
                passwordRuleErrorMessages.value = [
                    t(
                        'users.users_msg__password_form_new_password_do_not_match_confirm_password'
                    ),
                ];
                isSubmitting.value = false; // allow resubmission
                return;
            }

            if (passwordRuleErrorMessages.value.length > 0) {
                isSubmitting.value = false; // allow resubmission
                return;
            }

            emit('submit', { ...formData.value });
        };

        const handleNewPasswordBlur = async () => {
            if (!formData.value.newPassword) {
                return;
            }
            await validatePasswordRule();
        };

        const toggleOldPasswordVisibility = () => {
            oldPasswordVisible.value = !oldPasswordVisible.value;
        };

        const toggleNewPasswordVisibility = () => {
            newPasswordVisible.value = !newPasswordVisible.value;
        };

        const toggleConfirmPasswordVisibility = () => {
            confirmPasswordVisible.value = !confirmPasswordVisible.value;
        };

        return {
            formData,
            handleSubmit,
            passwordRuleErrorMessages,
            handleNewPasswordBlur,
            isSubmitting,
            oldPasswordVisible,
            newPasswordVisible,
            confirmPasswordVisible,
            toggleOldPasswordVisibility,
            toggleNewPasswordVisibility,
            toggleConfirmPasswordVisibility,
        };
    },
};
</script>

<style scoped>
.form-group {
    margin-bottom: 1rem;
    text-align: left;
}
</style>
