<template>
    <div class="card py-5 text-center">
        <div class="card-header">
            <h4 class="card-title">
                {{ $t('users.users_msg__batch_create_title') }}
            </h4>

            <p class="lead">
                {{ $t('users.users_msg__batch_create_greeting_msg') }}
            </p>
        </div>
        <div id="formUpload" class="card-body me-3 ms-3">
            <div
                class="d-flex align-items-center justify-content-start flex-wrap"
            >
                <!-- Download Button -->
                <button
                    class="btn btn-outline-info mx-2 button-custom"
                    id="btnDownloadTemplate"
                    @click="downloadTemplate"
                >
                    {{ $t('users.users_btn__download_template') }}
                </button>

                <!-- File Input and Choose Button -->
                <input
                    type="file"
                    accept=".xlsx"
                    id="xlsxFile"
                    name="xlsxFile"
                    ref="fileInput"
                    style="display: none"
                    @change="handleFileSelection"
                />
                <button
                    class="btn btn-outline-primary mx-2 button-custom"
                    id="btnChooseFile"
                    @click="triggerFileInput"
                >
                    {{ $t('users.users_btn__choose_file') }}
                </button>
                <button
                    class="btn btn-secondary ms-auto"
                    id="btnUpload"
                    :disabled="!selectedFile"
                    @click="uploadFile"
                >
                    {{ $t('users.users_btn__upload') }}
                </button>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center flex-wrap">
            <div class="col-md-12">
                <div id="divInvalidResult" v-if="invalidResults.length > 0">
                    <hr />
                    <div class="alert alert-danger" id="invalidUserAlert">
                        {{
                            $t(
                                'users.users_msg__batch_create_invalid_user_alert'
                            )
                        }}
                    </div>
                    <h2>
                        {{
                            $t(
                                'users.users_msg__batch_create_invalid_table_title'
                            )
                        }}
                    </h2>
                    <table
                        class="table table-striped nowrap w-100"
                        id="validationErrorTable"
                    >
                        <thead>
                            <tr>
                                <th
                                    v-for="(key, index) in Object.keys(
                                        invalidResults[0]
                                    )"
                                    :key="index"
                                >
                                    {{ $t(`users.users_fld__${key}`) }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(error, index) in invalidResults"
                                :key="index"
                            >
                                <td v-for="(value, key) in error" :key="key">
                                    {{ value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                </div>
                <div id="divValidResult" v-if="validResults.length > 0">
                    <h2>
                        {{
                            $t(
                                'users.users_msg__batch_create_valid_table_title'
                            )
                        }}
                    </h2>
                    <table
                        class="table table-striped nowrap w-100"
                        id="correctTable"
                    >
                        <thead>
                            <tr>
                                <th
                                    v-for="(key, index) in Object.keys(
                                        validResults[0]
                                    )"
                                    :key="index"
                                >
                                    {{ $t(`users.users_fld__${key}`) }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(result, index) in validResults"
                                :key="index"
                            >
                                <td v-for="(value, key) in result" :key="key">
                                    {{ value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { batchCreateUsers } from '@/api/users';

import TableComponent from '@/components/TableComponent.vue';

export default {
    name: 'UserBatchCreate',
    components: {
        TableComponent,
    },
    setup() {
        const fileInput = ref(null);
        const selectedFile = ref(null);
        const validResults = reactive([]);
        const invalidResults = reactive([]);
        const isSuccessModalVisible = ref(false);

        const { t } = useI18n();
        const Swal = inject('$swal');

        const triggerFileInput = () => {
            fileInput.value.click();
        };

        const handleFileSelection = async event => {
            const file = event.target.files[0];
            if (!file) return;
            if (
                file &&
                file.type ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                selectedFile.value = file;
                // call api to check the data in the xlsx file is valid or not
                const formData = new FormData();
                formData.append('file', selectedFile.value);
                formData.append('is_check', true);

                try {
                    const response = await batchCreateUsers(formData);
                    validResults.splice(0, validResults.length);
                    invalidResults.splice(0, invalidResults.length);

                    if (response.data.errors.length > 0) {
                        selectedFile.value = null;
                        response.data.errors.forEach(result => {
                            invalidResults.push(result);
                        });
                    }

                    if (response.data.valids.length > 0) {
                        response.data.valids.forEach(result => {
                            validResults.push(result);
                        });
                    }
                } catch (error) {
                    await Swal.fire({
                        title: t('users.users_msg__validation_failed'),
                        text: t(
                            'users.users_msg__validation_failed_some_thing_went_wrong'
                        ),
                        icon: 'error',
                    });
                    selectedFile.value = null;
                }
            } else {
                await Swal.fire({
                    title: t('users.users_msg__invalid_file'),
                    text: t('users.users_msg__invalid_file_msg'),
                    icon: 'error',
                });
                selectedFile.value = null;
            }
        };

        const uploadFile = async () => {
            if (!selectedFile.value) return;

            const formData = new FormData();
            formData.append('file', selectedFile.value);
            formData.append('is_check', false);

            try {
                const response = await batchCreateUsers(formData);
                console.log(response);
                if (
                    response.data.success_count > 0 &&
                    response.data.errors.length === 0
                ) {
                    await Swal.fire({
                        title: t('users.users_msg__batch_create_success'),
                        text: t('users.users_msg__batch_create_success_msg'),
                        icon: 'success',
                    });
                } else {
                    invalidResults.splice(0, invalidResults.length);
                    if (response.data.errors.length > 0) {
                        response.data.errors.forEach(result => {
                            invalidResults.push(result);
                        });
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                selectedFile.value = null;
            }
        };

        const downloadTemplate = async () => {
            // download the template file
            // template file is in @/public/docs/Batch upload users template.xlsx
            const templateFile = `${process.env.BASE_URL}docs/Batch upload users template.xlsx`;
            const link = document.createElement('a');
            link.href = templateFile;
            link.download = 'Batch upload users template.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return {
            fileInput,
            selectedFile,
            validResults,
            invalidResults,
            triggerFileInput,
            handleFileSelection,
            uploadFile,
            downloadTemplate,
            isSuccessModalVisible,
        };
    },
};
</script>

<style scoped>
/* Add your custom styles here if necessary */
</style>
